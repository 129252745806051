<template>
    <div class="NumberList">
        <div class="NumberList__body">
            <div class="NumberList__conditions">
                <radio-button v-model="active"
                              :active-style="{background: '#1677FF', color: '#FFFFFF'}"
                              :column="3"
                              :inactive-style="{background: '#FFFFFF', color: '#323233', border: 'none'}"
                              :items="items"
                              @change="onChange"
                />
            </div>
            <div class="NumberList__list">
                <div class="NumberList__listHeader">
                    <span class="Link Link--info">点击可查看号码详情</span>
                    <span class="Link" @click="onSort(query.orderByCallinMinFee)">
                        <i class="iconfont icon-paixu"></i>
                        {{ query.orderByCallinMinFee == '1' ? '月低消升序' : '月低消降序' }}
                    </span>
                </div>
                <div class="NumberList__listBody">
                    <num-pool v-if="['1', '2'].includes(query.numClass)" :key="query.numClass" ref="list" :query="query"></num-pool>
                    <num-destined v-else ref="list" :query="query"></num-destined>
                </div>
            </div>
        </div>
        <div class="NumberList__filter">
            <van-search v-model="query.number"
                        maxlength="10"
                        placeholder="请输入400号码，支持模糊搜索"
                        show-action
                        type="number"
                        @search="onSearch"
            >
                <van-button slot="action" size="small" type="info" @click="onFilters">
                    <i slot="icon" class="iconfont icon-shaixuan1" style="margin-right: 8px;"></i>筛选
                </van-button>
            </van-search>
        </div>
        <filters ref="filter" :query="query" @submit="onSearch"></filters>
        <!-- 号码详情 -->
        <router-view />
    </div>
</template>

<script>
    import Filters from "./components/Filters";
    import RadioButton from "@/components/RadioButton";
    import NumPool from "./components/NumPool";
    import NumDestined from "./components/NumDestined";
    import {mapActions, mapState} from "vuex";

    export default {
        name: "NumberList",
        components: {Filters, RadioButton, NumPool, NumDestined},
        data() {
            return {
                active: '1',
                query: {
                    batchNumber: '',
                    callinMinFeeType: '',
                    callinMinFeeTypeEnd: '',
                    callinMinFeeTypeStart: '',
                    exclude: '',
                    number: '',
                    numClass: '1',
                    orderByCallinMinFee: '1',
                    // sectionCode: '',
                    specialStart: '',
                    specialEnd: '',
                }
            }
        },
        computed: {
            ...mapState('number', ['dataCube']),
            items() {
                let text = '';
                if (this.dataCube) {
                    let {destineMax, desiningCount, desinedCount} = this.dataCube;
                    let sum = desiningCount + desinedCount;
                    text = `(${sum}/${destineMax})`;
                }
                return [
                    {text: '快开号', value: '1'},
                    {text: '可选号', value: '2'},
                    {text: `已占号${text}`, value: '3'},
                ];
            }
        },
        watch: {
            $route: 'getDataCube'
        },
        mounted() {
            this.getDataCube();
            this.getSections();
            this.getLevels();
        },
        methods: {
            ...mapActions('number', ['getSections', 'getLevels', 'getDataCube']),
            onSort(value) {
                this.query.orderByCallinMinFee = value == '1' ? '2' : '1';
                this.$nextTick(this.onSearch);
            },
            onChange(value) {
                this.query = {
                    batchNumber: '',
                    callinMinFeeType: '',
                    callinMinFeeTypeEnd: '',
                    callinMinFeeTypeStart: '',
                    exclude: '',
                    number: '',
                    numClass: value,
                    orderByCallinMinFee: '1',
                    specialStart: '',
                    specialEnd: '',
                };
            },
            onSearch() {
                this.$refs.list.onSearch();
            },
            onFilters() {
                this.$refs.filter.visible = true;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .NumberList {
        height: 100vh;
        background: #F7F8FA;
        display: flex;
        flex-direction: column;
        &__body {
            flex: 1;
            padding: 10px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }
        &__conditions {
            display: flex;
            gap: 0 10px;
            justify-content: space-between;
            margin-bottom: 16px;
            .van-button {
                flex: 1;
            }
        }
        &__list {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: auto;
            &Header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
            }
            &Body {
                flex: 1;
                overflow: auto;
            }
        }
        &__filter {
            .van-search__action {
                line-height: 1;
                .van-button {
                    height: 34px;
                    padding: 0 16px;
                    i {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .Link {
        font-size: 12px;
        color: #3C8FFF;
        cursor: pointer;
        line-height: 20px;
        &--info {
            color: #969799;
        }
    }
</style>
