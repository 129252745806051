<template>
    <my-scroll-list class="NumPool" ref="list" :option="{...option, query: _query}">
        <template #item="{item}">
            <div class="NumPool__item Item" @click="toDetail(item.number)">
                <div class="Item__number">
                    {{ item.number }}
                    <span v-if="item.operateStatus && item.operateStatus.length > 0"
                          style="font-size: 12px; color: #4AD07E; margin-left: 4px;"
                    >
                        {{ item.operateStatus | getMapText('number/operateStatus') }}
                    </span>
                </div>
                <div class="Item__row" style="margin-left: 16px;">
                    <span class="Item__row-label" style="width: fit-content;">月低消</span>
                    <span>{{ item.callinMinFee }}</span>
                </div>
                <div class="Item__state">
                    <van-icon :size="12" color="#8F9295" name="arrow" />
                </div>
            </div>
        </template>
    </my-scroll-list>
</template>

<script>
    import {MyScrollList} from "@/components/MyPlugins";
    import {getNumberList} from "@/api/number";

    export default {
        name: "NumPool",
        components: {MyScrollList},
        props: {
            query: Object
        },
        data() {
            return {
                option: {
                    api: getNumberList,
                    beforeQuery: (query) => {
                        // 1：大于，2：小于，3：等于，4：区间
                        let {callinMinFeeTypeStart, callinMinFeeTypeEnd/*, batchNumber*/} = query;
                        if (callinMinFeeTypeStart || callinMinFeeTypeEnd) {
                            // 区间
                            if (callinMinFeeTypeStart && callinMinFeeTypeEnd) {
                                query.callinMinFeeType = 4;
                            } else if (callinMinFeeTypeStart) { // 大于
                                query.callinMinFeeType = 1;
                            } else { // 小于
                                query.callinMinFeeType = 2;
                            }
                        }
                        // if (batchNumber) {
                        //     query.batchNumber = batchNumber.split("\n").filter(Boolean);
                        // }

                        return query;
                    },
                }
            }
        },
        computed: {
            _query() {
                return {
                    ...this.query,
                    // levelNum: '1',
                    // numClass: '1',
                    numStatus: '1',
                    // operateStatus: '1',
                    // typeCode: ''
                };
            }
        },
        methods: {
            toDetail(number) {
                this.$router.push({path: 'number-list/detail', query: {number}});
            },
            onSearch() {
                this.$refs.list.onSearch();
            },
        }
    }
</script>

<style lang="scss" scoped>
    .NumPool {
        &__item {
            padding: 16px;
            border-radius: 4px;
            background: #FFFFFF;
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .Item {
            display: flex;
            align-items: center;
            &__state {
                margin-left: auto;
                .van-icon {
                    margin-left: 10px;
                }
            }
            &__number {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                display: flex;
                align-items: center;
            }
            &__row {
                font-size: 12px;
                display: flex;
                gap: 0 8px;
                line-height: 18px;
                &-label {
                    color: #969799;
                    width: 72px;
                    display: inline-block;
                }
            }
        }
    }
</style>
