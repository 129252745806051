<template>
    <my-scroll-list ref="list" :option="{...option, query: _query}" class="NumDestined">
        <template #item="{item}">
            <div class="NumDestined__item Item" @click="toDetail(item)">
                <div class="Item__info">
                    <div class="Item__number">
                        {{ item.number }}
                        <span style="font-size: 12px; color: #1677FF; margin-left: 4px;">
                            {{ item.numClass == 1 ? '快开号' : '可选号' }}
                        </span>
                    </div>
                    <div class="Item__row" style="margin: 4px 0;">
                        <span class="Item__row-label">月低消</span>
                        <span>{{ item.callinMinFee }}</span>
                    </div>
                    <div class="Item__row">
                        <span class="Item__row-label">预占到期时间</span>
                        <span>{{ item.destineEndTime }}</span>
                    </div>
                </div>
                <div class="Item__state">
                    <my-label v-if="item.isOrder && item.isOrder == 1" :alpha="0.1" color="#4AD07E" size="small" text="已提单"></my-label>
                    <my-label v-else :alpha="0.1" color="#969799" size="small" text="未提单"></my-label>
                    <van-icon :size="12" color="#8F9295" name="arrow" />
                </div>
            </div>
        </template>
    </my-scroll-list>
</template>

<script>
    import {MyScrollList, MyLabel} from "@/components/MyPlugins";
    import {getNumDestined} from "@/api/number";
    import {mapActions} from "vuex";

    export default {
        name: "NumDestined",
        components: {MyScrollList, MyLabel},
        props: {
            query: Object
        },
        data() {
            return {
                option: {
                    api: getNumDestined,
                    beforeQuery: (query) => {
                        // 1：大于，2：小于，3：等于，4：区间
                        let {callinMinFeeTypeStart, callinMinFeeTypeEnd/*, batchNumber*/} = query;
                        if (callinMinFeeTypeStart || callinMinFeeTypeEnd) {
                            // 区间
                            if (callinMinFeeTypeStart && callinMinFeeTypeEnd) {
                                query.callinMinFeeType = 4;
                            } else if (callinMinFeeTypeStart) { // 大于
                                query.callinMinFeeType = 1;
                            } else { // 小于
                                query.callinMinFeeType = 2;
                            }
                        }
                        // if (batchNumber) {
                        //     query.batchNumber = batchNumber.split("\n").filter(Boolean);
                        // }

                        return query;
                    },
                }
            }
        },
        computed: {
            _query() {
                return {
                    ...this.query,
                    // destinedTimeType: '1',
                    // destinedTimeStart: '',
                    // destinedTimeEnd: '',
                    orderByStatus: '1',
                    // orderStatus: '1'
                };
            }
        },
        mounted() {
            this.getDataCube();
        },
        methods: {
            ...mapActions('number', ['getDataCube']),
            toDetail(item) {
                let {number, sectionCode} = item;
                this.$router.push({path: 'number-list/detail1', query: {number, sectionCode}});
            },
            onSearch() {
                this.$refs.list.onSearch();
            },
        }
    }
</script>

<style lang="scss" scoped>
    .NumDestined {
        &__item {
            padding: 16px;
            border-radius: 4px;
            background: #FFFFFF;
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .Item {
            display: flex;
            align-items: center;
            &__state {
                margin-left: auto;
                .van-icon {
                    margin-left: 10px;
                }
            }
            &__number {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                display: flex;
                align-items: center;
            }
            &__row {
                font-size: 12px;
                display: flex;
                gap: 0 8px;
                line-height: 18px;
                &-label {
                    color: #969799;
                    width: 72px;
                    display: inline-block;
                }
            }
        }
    }
</style>
