<template>
    <my-drawer v-model="visible"
               :fullscreen="false"
               :nav-left-arrow="false"
               position="bottom"
               round
               @close="onClose"
    >
        <template #nav-title>
            <div>更多筛选</div>
            <div style="color: #969799; font-size: 12px;">通过以下条件快速找到中意号码</div>
        </template>
        <template #nav-right>
            <van-icon color="#323233" name="cross" size="18" @click="onClose" />
        </template>
        <div class="Filters">
            <div class="Filters__tab">
                <radio-button v-model="active"
                              :active-style="{background: '#1677FF', color: '#FFFFFF'}"
                              :column="2"
                              :inactive-style="{background: '#F7F8FA', color: '#323233', border: 'none'}"
                              :items="items"
                              @change="doReset"
                />
            </div>
            <van-form>
                <template v-if="active == 1">
                    <my-item label="号码月低消">
                        <van-row align="center" type="flex">
                            <van-col span="11">
                                <van-field v-model="query.callinMinFeeTypeStart" placeholder="自定义低价" type="number" />
                            </van-col>
                            <van-col span="2" style="text-align: center; line-height: 1;">至</van-col>
                            <van-col span="11">
                                <van-field v-model="query.callinMinFeeTypeEnd" placeholder="自定义高价" type="number" />
                            </van-col>
                        </van-row>
                    </my-item>
                    <my-item label="排除号码">
                        <van-field v-model="query.exclude" placeholder="如：输入47，系统将不在返回包含4与7的号码" type="number" />
                    </my-item>
                    <my-item label="特殊开头">
                        <van-field v-model="query.specialStart"
                                   v-limit="/[^0-9,]/g"
                                   inputmode="numeric"
                                   placeholder="支持同时查询多种组合，如：400800,4001001"
                        />
                    </my-item>
                    <my-item label="特殊尾号">
                        <van-field v-model="query.specialEnd"
                                   v-limit="/[^0-9,]/g"
                                   inputmode="numeric"
                                   placeholder="支持同时查询多种组合，如：8888,666"
                        />
                    </my-item>
                </template>
                <my-item v-else label="号码批量查询">
                    <van-field v-model="query.batchNumber"
                               v-limit="/[^0-9\n]/g"
                               :placeholder="'最多支持50个号码批量查询，如：\n4008001234\n4001005678'"
                               inputmode="numeric"
                               maxlength="500"
                               rows="10"
                               type="textarea"
                    />
                </my-item>
            </van-form>
        </div>
        <template #footer>
            <van-button block native-type="button" type="default" @click="onSubmit(true)">重置</van-button>
            <van-button block native-type="button" type="info" @click="onSubmit()">查询</van-button>
        </template>
    </my-drawer>
</template>

<script>
    import {MyDrawer, MyItem} from "@/components/MyPlugins";
    import RadioButton from "@/components/RadioButton";

    export default {
        name: "Filters",
        components: {MyDrawer, MyItem, RadioButton},
        props: {
            query: Object
        },
        data() {
            return {
                items: [
                    {text: '模糊查询', value: 1},
                    {text: '批量查询', value: 2},
                ],
                active: 1,
                visible: false,
                value: '',
            }
        },
        methods: {
            onClose() {
                this.visible = false;
            },
            async doReset() {
                this.query.callinMinFeeTypeStart = '';
                this.query.callinMinFeeTypeEnd = '';
                this.query.exclude = '';
                this.query.specialStart = '';
                this.query.specialEnd = '';
                this.query.batchNumber = '';
            },
            async onSubmit(reset = false) {
                if (reset) await this.doReset();
                this.$emit('submit');
                this.onClose();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .Filters {
        padding: 20px 16px;
        &__tab {
            margin-bottom: 20px;
        }
    }
    ::v-deep {
        .van-cell-group__title {
            padding: 0 0 10px 0;
        }
    }
</style>
